/* eslint react/jsx-no-target-blank: 0 */
import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"

import Close from "../icons/close"

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transition: "0.5s all linear",
  },

  backdrop: {
    position: "fixed",
    background: "#00000080",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -1,
  },

  popupImageContainer: {
    position: "relative",
    boxSizing: "border-box",
    margin: "0 20px",

    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  popup: {
    maxWidth: "777px",
    display: "block",
    width: "100%",
    height: "auto",
  },

  closeBtnContainer: {
    position: "absolute",
    top: "1.72%",
    right: "1.15%",
    background: "#1A65FF",
    width: "20px",
    height: "20px",

    [theme.breakpoints.down("xs")]: {
      top: "0.9%",
      right: "2.33%",
    },
  },

  closeBtn: {
    width: "20px",
    height: "20px",
    verticalAlign: "unset",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },
})

const CenterPopup = ({ classes, showPopup, togglePopup }) => (
  <div
    className={classes.root}
    style={{
      opacity: showPopup ? 1 : 0,
      visibility: showPopup ? "visible" : "hidden",
    }}
  >
    <div
      className={classes.backdrop}
      role="button"
      tabIndex="0"
      onClick={togglePopup}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          togglePopup()
        }
      }}
    >
    </div>
    <div className={classes.popupImageContainer}>
      <a
        href="https://keymanletter.com/"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source
            srcSet={require(`../../images/kdb-banner-3.webp`) + ", " + require(`../../images/kdb-banner-3@2x.webp`) + " 2x"}
            height={657}
            width={392}
            media="(max-width: 599px)"
            type="image/webp"
          />
          <source
            srcSet={require(`../../images/kdb-banner-3.png`) + ", " + require(`../../images/kdb-banner-3@2x.png`) + " 2x"}
            height={657}
            width={392}
            media="(max-width: 599px)"
          />
          <source
            srcSet={require(`../../images/kdb-banner-2.webp`) + ", " + require(`../../images/kdb-banner-2@2x.webp`) + " 2x"}
            height={356}
            width={795}
            media="(min-width: 600px)"
            type="image/webp"
          />
          <source
            srcSet={require(`../../images/kdb-banner-2.png`) + ", " + require(`../../images/kdb-banner-2@2x.png`) + " 2x"}
            height={356}
            width={795}
            media="(min-width: 600px)"
          />
          <source
            srcSet={require(`../../images/kdb-banner-2.webp`) + ", " + require(`../../images/kdb-banner-2@2x.webp`) + " 2x"}
            height={356}
            width={795}
            className={classes.popup}
            type="image/webp"
          />
          <img
            srcSet={require(`../../images/kdb-banner-2.png`) + ", " + require(`../../images/kdb-banner-2@2x.png`) + " 2x"}
            height={356}
            width={795}
            className={classes.popup}
            alt="kdb-banner.png"
          />
        </picture>
      </a>
      <div className={classes.closeBtnContainer}>
        <IconButton className={classes.closeBtn} onClick={togglePopup} aria-label="close">
          <Close color="#FFFFFF" width="14" height="14" />
        </IconButton>
      </div>
    </div>
  </div>
)

CenterPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  showPopup: PropTypes.bool.isRequired,
  togglePopup: PropTypes.func.isRequired,
}

export default withStyles(styles)(CenterPopup)
