import React from "react"
import PropTypes from "prop-types"

const People = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path id="Shape" d="M0,0H20V20H0Z" fill="none" fillRule="evenodd" />
      <path
        id="Combined-Shape"
        d="M15.833,12.167a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,15.833,12.167Zm-7.5-2.5a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,8.333,9.667Z"
        transform="translate(-0.833 -0.5)"
        fill={color}
        opacity="0.3"
      />
      <path
        id="Combined-Shape-2"
        data-name="Combined-Shape"
        d="M14.668,14.667c2.839.031,5.157,1.466,5.331,4.5a.425.425,0,0,1-.452.5H16.333A8.3,8.3,0,0,0,14.668,14.667ZM0,19c.323-3.977,3.551-6,7.486-6,3.99,0,7.268,1.911,7.512,6a.572.572,0,0,1-.626.667H.606A.923.923,0,0,1,0,19Z"
        transform="translate(0 -2.167)"
        fill={color}
      />
    </svg>
  )
}

People.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

People.defaultProps = {
  color: "#FFFFFF",
  width: 20,
  height: 20,
}

export default People
