import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { connect } from "react-redux"
import { Location } from "@reach/router"
import CenterPopup from "../components/common/center-popup"

import * as actions from "../state/actions/keymans"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import KeymanItem from "../components/common/keyman-item"
import Building from "../components/icons/building"
import People from "../components/icons/people"
import Search from "../components/icons/search"
import Pagination from "../components/common/pagination"
import Banner from "../components/common/banner"
import BreadcrumbList from "../components/common/breadcrumb-list"

import { KEYMAN_PER_PAGE } from "../constants/constants"
import { getQueryVariable } from "../utils/str"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"


export const query = graphql`
  query Firmographic($slug: String) {
    corporationsJson(fields: { slug: { eq: $slug } }) {
      id
      name
      shikiho_feature
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.firmographic.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.firmographic.pageInfo_keyman,
    isLoadingKeymans: state.firmographic.isLoadingKeymans,
    isInitialized: state.firmographic.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
  }
}

const styles = theme => ({
  firmographicContainer: {
    marginBottom: "18px",
    fontSize: "13px",
  },

  button: {
    color: "#5A5B62",
    background: "#FFFFFF",
    fontSize: "12px",
    padding: "4px 10px",
    whiteSpace: "nowrap",
    marginBottom: "7px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
})

class Firmographic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCenterPopup: false,
    }
  }

  componentDidMount() {
    this.props.getKeymans(this.getQuery(this.props), true)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoadingKeymans && prevProps.isLoadingKeymans) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getKeymans(this.getQuery(this.props))
    }
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: KEYMAN_PER_PAGE,
      page: 1,
      "corporation_id[]": props.data.corporationsJson.id,
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  getToCount = (props, query = {}) => {
    return {
      paging: 1,
      per_page: KEYMAN_PER_PAGE,
      page: 1,
      "corporation_id[]": props.data.corporationsJson.id,
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  toggleCenterPopup = () => {
    sessionStorage.setItem("showCenterPopup", false)
    this.setState({
      showCenterPopup: false,
    })
  }

  showCenterPopup = () => {
    sessionStorage.setItem("showCenterPopup", true)
    this.setState({
      showCenterPopup: true,
    })
  }

  render() {
    const {
      classes,
      isLoadingKeymans,
      pageInfo_keyman,
      data,
      isInitialized,
    } = this.props

    let keymans =
      !isInitialized ||
      (isLoadingKeymans &&
        this.props.keymans &&
        this.props.keymans.length === 0)
        ? Array.from(Array(KEYMAN_PER_PAGE))
        : this.props.keymans

    const title = `${
      data.corporationsJson.name ? data.corporationsJson.name + "の" : ""
    }決裁者一覧`
    const totalCount = `${this.props.pageInfo_keyman
      ? pageInfo_keyman.total
      : 0}`
    const fromCount = `${this.props.pageInfo_keyman && totalCount !== 0
      ? pageInfo_keyman.from +1
      : 0}`
    const toCount = `${this.props.pageInfo_keyman && pageInfo_keyman.current_page < pageInfo_keyman.last_page
      ? pageInfo_keyman.to
      : totalCount}`

    return (
      <Layout hasFooterPopup={true}>
        <SEO
          title={title}
          description={`${
            data.corporationsJson.name
              ? data.corporationsJson.name + "の決裁者一覧です。"
              : ""
          }大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
          canonical={`https://keyman-db.smart-letter.com/corporations/${this.props.data.corporationsJson.id}/keymans`}
        />

        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[{ path: "/", text: "トップ" }]}
                current={{
                  path: `/corporations/${this.props.data.corporationsJson.id}/keymans`,
                  text: title,
                }}
              />
              <h2 className="title">{title}</h2>
              <ServiceDescription
                children={<LinkToLpText/>}
              />
              <div className={classes.firmographicContainer}>
                {data.corporationsJson.name ? data.corporationsJson.name : ""}の決裁者{keymans.length !== 0 ? totalCount + "人中" + fromCount + "~" + toCount + "人目まで" : "0人"}を表示しています。
                <Box mt={2} display="flex">
                  <Button style={{marginLeft:"0px"}} className={classes.button} component={Link}
                    to={`/corporations/${this.props.data.corporationsJson.id}/positions`}
                    variant="contained">
                    <People color='secondary'/>役職で絞り込む
                  </Button>
                  <Button className={classes.button} component={Link}
                    to={`/corporations/${this.props.data.corporationsJson.id}/departments`}
                    variant="contained">
                    <Building color='secondary'/>部門で絞り込む
                  </Button>
                  < Button className={classes.button} onClick={this.showCenterPopup} variant="contained">
                    <Search />キーワードで絞り込む
                  </ Button>
                </Box>
              </div>
              {keymans.length === 0 ? (
                <div>決裁者がありません。</div>
              ) : (
                <div className="list">
                  {keymans.map((item, i) => (
                    <KeymanItem
                      item={item}
                      key={i}
                      showIndustry={true}
                      showDepartment={true}
                      showBorderBottom={i !== keymans.length - 1}
                    />
                  ))}
                </div>
              )}

              <CenterPopup
                showPopup={this.state.showCenterPopup}
                togglePopup={this.toggleCenterPopup}
              />

              {keymans.length > 0 && pageInfo_keyman ? (
                <Pagination
                  currentPage={pageInfo_keyman.current_page}
                  lastPage={pageInfo_keyman.last_page}
                  perPage={KEYMAN_PER_PAGE}
                  total={pageInfo_keyman.total}
                  onChange={(params, overwrite) =>
                    this.props.getKeymans(
                      this.getQuery(this.props, params),
                      overwrite
                    )
                  }
                  disabled={isLoadingKeymans}
                  path={`corporations/${this.props.data.corporationsJson.id}/keymans`}
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>
            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>
            </div>
          </div>
          <Banner />
        </div>
      </Layout>
    )
  }
}

Firmographic.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Firmographic {...locationProps} {...props} />}
    </Location>
  ))
)
